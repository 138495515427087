<template>
  <LxpMobileHeader title="판매자격연수 현황">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="closeModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-talentboard-admin">
    <div class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(tab, index) in view.tabs" class="snb-item" :class="{'is-active': view.current === tab.key}" :key="index">
              <button @click="view.current = tab.key">{{ tab.name }}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- main-content -->
    <div class="main-content">
      <div class="profile-list-container">
        <section class="content-section">
          <header class="section-header">
<!--            <h3 class="title font-bodyB">{{ currentTab.name }}</h3>-->
          </header>
          <div class="content-scrollwrap d-block">
            <div class="kb-table kb-table-bordered kb-table-padding kb-table-equal-padding" style="min-width: 1070px;">
              <table>
                <colgroup>
                  <col>
                  <col>
                  <col>
                  <col>
                  <col v-if="view.current === '2066015'">
                  <col v-if="view.current !== '2066015'">
                  <col v-if="view.current !== '2066015'">
                </colgroup>
                <thead>
                <tr>
                  <th class="cell-border"><span class="th-title">과정차수명</span></th>
                  <th class="cell-border"><span class="th-title">학습기간</span></th>
                  <th v-if="view.current === '2066015'" class="cell-border"><span class="th-title">구분</span></th>
                  <th class="cell-border"><span class="th-title">콘텐츠명</span></th>
                  <th class="cell-border"><span class="th-title">{{view.current === '2066015' ? '학습시간' : '누적 진도율'}}</span></th>
                  <th v-if="view.current !== '2066015'" class="cell-border"><span class="th-title">콜드콜</span></th>
                  <th v-if="view.current !== '2066015'" class="cell-border"><span class="th-title">학습완료여부</span></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, index) in renderItems" :key="index">
                  <tr v-if="item.learns && item.learns.length > 0">
                    <td :rowspan="item.learns.length" class="padding-12 cell-border"><span class="td-text">{{item.crseNm}}</span></td>
                    <td :rowspan="item.learns.length" class="padding-12 cell-border"><span class="td-text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~ {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                    <td v-if="view.current === '2066015'" class="padding-12 cell-border"><span class="td-text">{{item.learns[0].chapNm ? item.learns[0].chapNm: '-'}}</span></td>
                    <td class="padding-12 cell-border"><span class="td-text">{{item.learns[0].objNm}}</span></td>
                    <td class="padding-12 cell-border"><span class="td-text" v-html="getScore(item.learns[0], view.current)"></span></td>
                    <td v-if="view.current !== '2066015'" class="padding-12 cell-border">
                      <span class="td-text " v-html="getColdCall(item.learns[0], item.lrnTrgtGrpDtlSn)"></span>
                    </td>
                    <td v-if="view.current !== '2066015'" class="padding-12 cell-border">
                      <span class="td-text" v-html="getFinishText(item, item.learns[0])"></span>
                    </td>
                  </tr>
                  <template v-for="(learn, idx) in item.learns" :key="`${index}-${idx}`">
                    <tr v-if="idx > 0">
                      <td v-if="view.current === '2066015'" class="padding-12 cell-border"><span class="td-text">{{learn.chapNm ? learn.chapNm : '-'}}</span></td>
                      <td class="padding-12 cell-border"><span class="td-text">{{learn.objNm}}</span></td>
                      <td class="padding-12 cell-border"><span class="td-text" v-html="getScore(learn, view.current)"></span></td>
                      <td v-if="view.current !== '2066015'" class="padding-12 cell-border">
                        <span class="td-text" v-html="getColdCall(learn, item.lrnTrgtGrpDtlSn)"></span>
                      </td>
                      <td v-if="view.current !== '2066015'" class="padding-12 cell-border">
                        <span class="td-text" v-html="getFinishText(item, learn)"></span>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div>
          </div>

        </section>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import {learnRegularStatusSetup} from '@/assets/js/modules/learn/learn-regular-status-setup';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';

export default {
  name: 'MobileLearnRegularStatus',
  components: {LxpMobileHeader},
  props: {
    toggle: Boolean,
    items: Array
  },
  emits: ['update:toggle'],
  setup: learnRegularStatusSetup
};
</script>

